/* eslint-disable perfectionist/sort-imports */
import "@solvari/common-fe/polyfills";
import "@/platform/utils/sentry";

import { initArgus } from "@solvari/common-fe/argus/service";
import { initAbTesting } from "@solvari/common-fe/ab-testing";
import { initHeyflowForm } from "@/shared/components/heyflow-form/heyflowForm";

import "@/platform/layouts/default/default.css";
import "@/platform/components/header/header";
import "@/platform/components/review-widget/reviewScore.css";
import "@/shared/components/pro/s-company-card/sCompanyCard.css";
import "@/shared/components/pro/s-company-badges/sCompanyBadge.css";
import "@/platform/components/s-visited-pages/sVisitedPages";
import "@/platform/components/footer/footer";
import { initPlatformVueIslands } from "@/platform/vueIslands";
import { initFontAwesome } from "@solvari/common-fe/integrations";

/* eslint-enable perfectionist/sort-imports */

function main() {
  void initArgus();
  void initAbTesting();
  initFontAwesome();
  initPlatformVueIslands();
  void initHeyflowForm();
}

main();
